import { React, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
// import { TextInput, Checkbox, Box, Select, Modal } from '@mantine/core';
// import { useDisclosure } from '@mantine/hooks';
// import { useForm } from '@mantine/form';

import Navbar from '../Navbar/NavbarPublic';
import Footer from '../Footer/Footer';
// import SyndicRectangleButton from '../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import LogoLogin from '../../composents/Logo/LoginLogo/LogoLogin';
// import RectangleButtonDisabled from '../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';

const SignUp = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // const [opened, { open, close }] = useDisclosure(false);
    
    // const navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    },[])

    // const form = useForm({
    //     initialValues: {
    //         typeOfCustomer: '',
    //         lastname: '',
    //         firstname: '',
    //         email: '',
    //         company: '',
    //         phone: '',
    //         unis: false,
    //         rgpd: false,
    //     },

    //     validate: {
    //         typeOfCustomer : (value) => (value.length < 1 ?  'Sélectionnez' : null),
    //         lastname : (value) => (
    //             value.length < 1
    //             ? 'Renseignez votre nom'
    //             : value.length > 30 
    //             ? '30 caractères max.' 
    //             : null
    //         ),
    //         firstname : (value) => (
    //             value.length < 1 
    //             ? 'Renseignez votre prénom'
    //             : value.length > 30 
    //             ? '30 caractères max.'
    //             : null
    //         ),
    //         email : (value) => (
    //             value.length < 1
    //             ? 'Renseignez votre E-mail'
    //             : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
    //             ? null 
    //             : 'E-mail non valide'
    //         ), 
    //         company : (value) => (
    //             value.length < 1
    //             ? 'Renseignez le nom de société'
    //             : value.length > 30 
    //             ? '30 caractères max.'
    //             : null
    //         ),
    //         phone: (value) => (
    //             value.length < 10 || value.length > 10 
    //             ? 'Le numéro de téléphone doit comporter 10 chiffres' 
    //             : null
    //         )

    //     },

    // });

    // const handleFormSubmit = async (values, _event) => {
    //     _event.preventDefault();
    //     try {
    //         await fetch("https://boldee-97d8d36af3bd.herokuapp.com/users/signup", {
    //             method: "POST",
    //             body: JSON.stringify({
    //                 typeOfCustomer: values.typeOfCustomer,
    //                 lastname: values.lastname,
    //                 firstname: values.firstname,
    //                 phone: values.phone,
    //                 email: values.email,
    //                 company: values.company,
    //                 unis: values.unis,
    //                 rgpd: values.rgpd
    //             }),
    //             headers: { "Content-Type": "application/json" }
    //         });

    //         open();
    //         setTimeout(() => {
    //             navigate('/');
    //         }, 1000)
    //     } catch (error) {
    //     }
    // }

    return(        
        <div>
            <Navbar/>
            {isMobile ? (
                <>
                    <div className="width90pCenter">
                        <div className="mrgT150"></div>
                        <div className="txtCenter"><LogoLogin /></div>
                        <p id="signUpTitle">S'inscrire</p>
                        <p id="loginSubtitle">Je suis déjà membre.  
                            <Link to="/#anchor">
                                <span className="aReset bT underline mrgL7 bold">
                                    Me connecter
                                </span>
                            </Link></p>
                        <p id="signUpSubtitle" className='mrgB10'>
                        Pour vous inscrire, veuillez envoyer un email à notre adresse  : <span className='mediumplus terracotaT'>contact@boldee.co</span>
                        </p>
                        
                    </div>
                    {/* <Box sx={{ maxWidth: 360 }} mx="auto">
                        <form onSubmit={form.onSubmit(handleFormSubmit)}>
                            <Select
                                label="Vous êtes"
                                placeholder="Sélectionnez"
                                withAsterisk
                                data={[
                                    { value: 'Syndic de copropriétés', label: 'Syndic de copropriétés' },
                                    { value: 'Prestataire', label: 'Prestataire' },
                                ]}
                                {...form.getInputProps('typeOfCustomer')}
                            />
                            <div className="mrgB10"></div>

                            <TextInput
                                withAsterisk
                                label="Email"
                                placeholder="votre@email.com"
                                {...form.getInputProps('email')}
                            />
                            <div className="mrgB10"></div>

                            <TextInput
                                withAsterisk
                                label="Nom"
                                placeholder="Nom"
                                {...form.getInputProps('lastname')}
                            />
                            <div className="mrgB10"></div>

                            <TextInput
                                withAsterisk
                                label="Prénom"
                                placeholder="Prénom"
                                {...form.getInputProps('firstname')}
                            />
                            <div className="mrgB10"></div>
                            
                            <TextInput
                                withAsterisk
                                label="Nom de société"
                                placeholder="Nom de l'entreprise"
                                {...form.getInputProps('company')}
                            />
                            <div className="mrgB10"></div>
                            
                            <TextInput
                                label="N° de téléphone"
                                placeholder="N° de téléphone"
                                {...form.getInputProps('phone')}
                            />
                            <div className="mrgB10"></div>

                            <Checkbox
                                mt="md"
                                label="Je suis adhérent UNIS" 
                                color="dark"
                                {...form.getInputProps('unis', { type: 'checkbox' })}
                            />

                            <div className="mrgB10"></div>   
   
                            <Checkbox
                                required
                                mt="md"
                                label="J'accepte que les informations saisies soient exploitées dans le cadre de la relation commerciale qui peut en découler." 
                                color="dark"
                                {...form.getInputProps('rgpd', { type: 'checkbox' })}
                            />

                            <div className="mrgB30"></div>

                            <p id="noteRGDP">Pour en savoir plus, notamment sur vos droits relatifs à vos données personnelles, consultez notre 
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/RGPD-P-001_-_Politique_de_confidentialit%C3%A9_v.3_1223_gcrwbv.pdf" target="_blank" rel="noreferrer" className="aReset bT underline mrgL5"> 
                                   politique de confidentialité
                                </a>
                            </p>
                            <div className="mrgB5"></div>
                            
                            {form.isDirty() 
                                ?   <SyndicRectangleButton type="submit">
                                        Envoyer
                                    </SyndicRectangleButton>
                                :   <RectangleButtonDisabled>
                                        Envoyer
                                    </RectangleButtonDisabled>
                            }
                            {open  
                                ?   <Modal 
                                        centered
                                        size="55%"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                            Votre demande d'inscription a été enregistrée
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                            <div className="mrgB150"></div>
                        </form>
                    </Box> */}
                </>
            ) : (
                <>
                    <div className="width90pCenter">
                        <div className="mrgT200"></div>
                        <p id="signUpTitle">S'inscrire</p>
                        <p id="loginSubtitle">Je suis déjà membre.  
                            <Link to="/#anchor">
                                <span className="aReset bT underline mrgL7 bold">
                                    Me connecter
                                </span>
                            </Link></p>
                            <div className='width30pCenter'>
                                <p id="signUpSubtitle" className='mrgB20'>
                                Pour vous inscrire, veuillez envoyer un email à notre adresse  : <span className='mediumplus terracotaT'>contact@boldee.co</span>
                                </p>
                            </div>

                    </div>
                    {/* <Box sx={{ maxWidth: 300 }} mx="auto">
                        <form onSubmit={form.onSubmit(handleFormSubmit)}>
                            <Select
                                label="Vous êtes"
                                data={[
                                    { value: 'Syndic de copropriétés', label: 'Syndic de copropriétés' },
                                    { value: 'Prestataire', label: 'Prestataire' },
                                ]}
                                size="lg"
                                {...form.getInputProps('typeOfCustomer')}
                            />
                            <div className="mrgB10"></div>

                            <TextInput
                                withAsterisk
                                label="Email"
                                placeholder="votre@email.com"
                                size="lg"
                                {...form.getInputProps('email')}
                            />
                            <div className="mrgB10"></div>

                            <TextInput
                                withAsterisk
                                label="Nom"
                                placeholder="Nom"
                                size="lg"
                                {...form.getInputProps('lastname')}
                            />
                            <div className="mrgB10"></div>

                            <TextInput
                                withAsterisk
                                label="Prénom"
                                placeholder="Prénom"
                                size="lg"
                                {...form.getInputProps('firstname')}
                            />
                            <div className="mrgB10"></div>
                            
                            <TextInput
                                withAsterisk
                                label="Nom de société"
                                placeholder="Nom de l'entreprise"
                                size="lg"
                                {...form.getInputProps('company')}
                            />
                            <div className="mrgB10"></div>
                            
                            <TextInput
                                label="N° de téléphone"
                                placeholder="N° de téléphone"
                                size="lg"
                                {...form.getInputProps('phone')}
                            />
                            <div className="mrgB10"></div>

                            <Checkbox
                                mt="md"
                                label="Je suis adhérent UNIS" 
                                color="dark"
                                size="lg"
                                {...form.getInputProps('unis', { type: 'checkbox' })}
                            />
                            <div className="mrgB10"></div>   

                            <Checkbox
                                required
                                mt="md"
                                label="J'accepte que les informations saisies soient exploitées dans le cadre de la relation commerciale qui peut en découler." 
                                color="dark"
                                size="lg"
                                {...form.getInputProps('rgpd', { type: 'checkbox' })}
                            />

                            <div className="mrgB30"></div>

                            <p id="noteRGDP">Pour en savoir plus, notamment sur vos droits relatifs à vos données personnelles, consultez notre  
                            <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/RGPD-P-001_-_Politique_de_confidentialit%C3%A9_v.3_1223_gcrwbv.pdf" target="_blank" rel="noreferrer" className="mrgL3 aReset bT underline">politique de confidentialité</a></p>
                            <div className="mrgB5"></div>
                            
                            {form.isDirty() 
                                ?   <SyndicRectangleButton type="submit">
                                        Envoyer
                                    </SyndicRectangleButton>
                                :   <RectangleButtonDisabled>
                                        Envoyer
                                    </RectangleButtonDisabled>
                            }
                            {open  
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt20 txtCenter noMrgTB">
                                            Votre demande d'inscription a été enregistrée
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                            <div className="mrgB150"></div>
                        </form>
                    </Box> */}
                </>
            )}    
            <Footer/>
        </div>
    );
}
export default SignUp;