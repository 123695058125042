import { React, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Tabs } from '@mantine/core';
import { IconArticle, IconArrowAutofitDown } from '@tabler/icons-react';
import Navbar from '../Navbar/NavbarPublic';
import Footer from '../Footer/Footer';
import CookieConsent from "react-cookie-consent";
import Maintenance from './Maintenance';

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const maintenance = false;

    return(
        <>
            {maintenance ? (
                <Maintenance />
            ) : (
                <>
                    <Navbar/>
                        <div>
                            {isMobile ? (
                                <>
                                    <CookieConsent 
                                        debug={true}
                                        location="bottom"
                                        buttonText="Accepter"
                                        cookieName="complianceRGPD_ga"
                                        style={{ 
                                            background: "#303030",
                                            color: "#FFFFFF",
                                            fontFamily: "Roboto",
                                            display: "inline",
                                            textAlign: "left"
            
                                        }}
                                        buttonStyle={{ marginTop : "3px", marginBottom : "20px", fontWeight: "bold", color: "#000000", background: "#FFFFFF", fontSize: "13px", borderRadius: "2px", marginRight: "40px" }}
                                        enableDeclineButton
                                        declineButtonText="Refuser"
                                        declineButtonStyle={{ marginTop : "3px", marginBottom : "20px", color: "#FFFFFF", background: "#303030", fontSize: "13px", borderRadius: "2px", borderWidth:"10px", borderColor:"#FFFFFF", marginRight: "5px" }}
                                        expires={150}    
                                        >                                    
                                        <p className='txt13 noMrgB useRoboto'> 
                                            Nous utilisons des cookies pour optimiser votre expérience utilisateur et pour améliorer nos contenus.
                                        </p>
                                    </CookieConsent>
                                    <div id="mrgB75"></div>
                                    <div className="width95pCenter bLiteGrey vertical txtCenter mrgT30">
                                        <div className='width90pCenter'>
                                            <div>
                                                <p className='noMrgB txt25 bold mrgT50'>
                                                    Boldee.co, l'application qui simplifie vos travaux de copropriétés
                                                </p>
                                            </div>
                                            <div className='mrgT20'></div>
                                        </div>
                                        <div id="anchor">
                                            <Link to="/signup" className="aReset bT">
                                                <button id="homePageSignUpButton">
                                                    <p className='txt16 noMrgTB'>Demander votre démo</p>
                                                </button>
                                            </Link>
                                        </div>
                                        <div id='mrgB50'></div>
                                    </div>

                                    <div className='mrgB10'></div>
                                
                                    <div className="width95pCenter">
                                        <Tabs color="red" radius="xl" defaultValue="artisan">
                                            <Tabs.List position="left">
                                                    <Tabs.Tab value="artisan">
                                                        <div className='vertical iCenter'>
                                                            <p className='noMrgT mrgB5'><IconArticle size="1rem" /> </p>
                                                            <p id='togglePresta' className='noMrgTB bold useRoboto'>Accéder aux </p>
                                                            <p id='togglePresta' className='noMrgB mrgT5 bold useRoboto'>offres de travaux</p>
                                                        </div>
                                                    </Tabs.Tab>
                                                
                                                
                                                    <Tabs.Tab value="syndic">
                                                        <div className='vertical iCenter'>
                                                            <p className='noMrgT mrgB5'><IconArrowAutofitDown size="1rem" /> </p>
                                                            <p className='noMrgTB bold useRoboto'>Recevoir des devis</p>
                                                            </div>
                                                    </Tabs.Tab>
                                                
                                            </Tabs.List>

                                            <Tabs.Panel value="artisan" pt="xs">
                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt25 bold'>Générez des revenus</p>
                                                        <p className='txt16 medium mrgB20'>Saisissez les meilleurs opportunités d'offres de travaux instantanément depuis notre plateforme. Augmentez vos revenus dès aujourd'hui !</p>
                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="revenusGa4" className='noMrgTB'>Accéder aux offres</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt25 bold'>Valorisez votre savoir-faire</p>
                                                        <p className='txt16 medium mrgB10'>Boostez votre visibilité en ligne. Chacune de nos fonctionnalités sont conçues pour mettre en avant et tirer le meilleur parti de votre expertise et de votre savoir-faire.</p>
                                                        <p className='txt16 bold mrgB20'>Inscrivez-vous pour voir l’aperçu de votre page vitrine.</p>

                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="savoirfaireGa4" className='noMrgTB'>S'inscrire</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt25 bold'>Soyez recommandé</p>
                                                        <p className='txt16 medium mrgB20'>Cultivez et renforcez votre réputation auprès de votre réseau actuel vous garantissant des partenariats commerciaux durables.</p>

                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="recommandationGa4" className='noMrgTB'>Découvrir</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.Panel>

                                            <Tabs.Panel value="syndic" pt="xs">
                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt25 bold'>Gagnez du temps en centralisant vos informations en un seul endroit</p>
                                                        <p className='txt16 medium mrgB20'>Notre plateforme vous libère du temps offrant un accès facile et une collaboration optimale et efficace avec vos prestataires.</p>

                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="centralisationInfosGa4" className='noMrgTB'>Découvrir</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt25 bold'>Recevez les meilleurs devis pour répondre aux besoins de vos copropriétaires</p>
                                                        <p className='txt16 medium mrgB20'>Publiez vos offres de travaux et recevez plusieurs devis en quelques clics.</p>
                                                        
                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="devisGa4" className='noMrgTB'>Commencer</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt25 bold'>La satisfaction de vos copropriétaires est notre priorité </p>
                                                        <p className='txt16 medium mrgB20'>Chaque artisan et prestataire membre est sélectionné et qualifié pour son propre savoir-faire, expertise et professionnalisme vérifiés.</p>
                                                        
                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="satisfactionCoproprietairesGa4" className='noMrgTB'>Voir le réseau</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.Panel>
                                        </Tabs>
                                        </div>
                                        <div className='width95pCenter vertical jCenter mrgB20 mrgT20 mrgL20'>
                                            <p className='noMrgT mrgB5 capitals txt15 unisT'>Partenaires</p>
                                            <div className='horizontal'>
                                            <img    src="https://res.cloudinary.com/do9t6jjdy/image/upload/v1693726907/logo_unis_bt6ef6.jpg" 
                                                    alt='unis' 
                                                    className='imgUnisHome'>
                                            </img>
                                            <img    src="https://res.cloudinary.com/do9t6jjdy/image/upload/v1716493434/logo-france_innovation-2_erqtyz.png" 
                                                    alt='franceInnovation' 
                                                    className='imgFranceInnovationHome mrgL40'>
                                            </img>
                                            </div>
                                        </div>
                                        <div className="vertical mrgB10 bLiteGrey width95pCenter">
                                            <div className='width90pCenter'>
                                                <p className='mrgB10 bold txt25'>Vous êtes adhérent UNIS</p>
                                                <div className='vertical'>
                                                        <p className='txt16 mrgR20 lineH20 useRoboto noMrgTB'>
                                                            Vous bénéficiez d’une offre spéciale.
                                                        </p>
                                                        <p className='noMrgT mrgB20'>
                                                            Pour la découvrir, inscrivez-vous en cochant la case "Je suis adhérent UNIS".
                                                        </p>
                                                </div>
                                                <div className='mrgB20'>
                                                    <Link to="/signup" className="aReset bT">
                                                        <button id="homePageSignUpButton50w">
                                                            <p id="unisMemberGa4" className='noMrgTB'>S'inscrire</p>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mrgB20"></div>
                                    <div className='txt11 txtCenter mrgT20'>*Sous réserve d'éligibilité</div>


                                </>
                            ) : (
                                <>
                                    <CookieConsent 
                                        debug={true}
                                        location="bottom"
                                        buttonText="Accepter"
                                        cookieName="complianceRGPD_ga"
                                        style={{ 
                                            background: "#303030",
                                            color: "#FFFFFF",
                                            fontFamily: "Roboto",
                                            paddingLeft: "10px"
                                        }}
                                        buttonStyle={{ fontWeight: "bold", color: "#000000", background: "#FFFFFF", fontSize: "16px", borderRadius: "2px", marginRight: "40px" }}
                                        enableDeclineButton
                                        declineButtonText="Refuser"
                                        declineButtonStyle={{ color: "#FFFFFF", background: "#303030", fontSize: "16px", borderRadius: "2px", borderWidth:"10px", borderColor:"#FFFFFF", marginRight: "5px" }}
                                        expires={150}    
                                        >                                    
                                        <p className='txt16'> 
                                            Nous utilisons des cookies pour optimiser votre expérience utilisateur et pour améliorer nos contenus.
                                        </p>
                                    </CookieConsent>
                                    <div className="mrgB150"></div>
                                    <div className="width50pCenter bLiteGrey vertical txtCenter mrgT30 ">
                                        <div className='width90pCenter'>
                                            <div>
                                                <p className='noMrgB txt40 bold'>
                                                    Boldee.co, l'application qui simplifie vos travaux de copropriétés
                                                </p>
                                            </div>
                                            <div className='mrgT20'></div>                                           
                                        </div>
                                        <div id="anchor" className='mrgB20'>
                                            <Link to="/signup" className="aReset bT">
                                                <button id="homePageSignUpButton50w">
                                                    <p className='txt18 noMrgTB'>Demander votre démo</p>
                                                </button>
                                            </Link>
                                        </div>
                                        <div className='mrgB30'></div>
                                    </div>
                                    <div className='mrgB20'></div>
                                    <div className="width50pCenter">
                                        <Tabs color="red" radius="xl" defaultValue="artisan">
                                            <Tabs.List position="center" className='horizontal iEnd'>
                                                    <Tabs.Tab value="artisan">
                                                        <div className='horizontal iEnd'>
                                                            <p className='noMrgTB'><IconArticle size="2rem" /> </p>
                                                            <div className='vertical mrgL10'>
                                                                <p id='togglePresta' className='noMrgTB bold useRoboto txt20'>Accéder aux </p>
                                                                <p id='togglePresta' className='noMrgB mrgT5 bold useRoboto txt20'>offres de travaux</p>
                                                            </div>
                                                        </div>
                                                    </Tabs.Tab>
                                                
                                                
                                                    <Tabs.Tab value="syndic">
                                                        <div className='horizontal iCenter'>
                                                            <p className='noMrgTB'><IconArrowAutofitDown size="2rem" /> </p>
                                                            <p className='noMrgTB bold useRoboto txt20 mrgL10'>Recevoir des devis</p>
                                                            </div>
                                                    </Tabs.Tab>
                                                
                                            </Tabs.List>

                                            <Tabs.Panel value="artisan" pt="xs">
                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt30 bold'>Générez des revenus</p>
                                                        <p className='txt18 medium mrgB20'>Saisissez les meilleurs opportunités d'offres de travaux instantanément depuis notre plateforme. Augmentez vos revenus dès aujourd'hui !</p>
                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="revenusGa4" className='noMrgTB'>Accéder aux offres</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt30 bold'>Valorisez votre savoir-faire</p>
                                                        <p className='txt18 medium mrgB10'>Boostez votre visibilité en ligne. Chacune de nos fonctionnalités sont conçues pour mettre en avant et tirer le meilleur parti de votre expertise et de votre savoir-faire.</p>
                                                        <p className='txt18 bold mrgB20'>Inscrivez-vous pour voir l’aperçu de votre page vitrine.</p>

                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="savoirfaireGa4" className='noMrgTB'>Continuer</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt30 bold'>Soyez recommandé</p>
                                                        <p className='txt18 medium mrgB20'>Cultivez et renforcez votre réputation auprès de votre réseau actuel vous garantissant des partenariats commerciaux durables.</p>

                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="recommandationGa4" className='noMrgTB'>Découvrir</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.Panel>

                                            <Tabs.Panel value="syndic" pt="xs">
                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt30 bold'>Gagnez du temps en centralisant vos informations en un seul endroit</p>
                                                        <p className='txt18 mrgB20'>Notre plateforme vous libère du temps offrant un accès facile et une collaboration optimale et efficace avec vos prestataires.</p>

                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="centralisationInfosGa4" className='noMrgTB'>Continuer</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt30 bold'>Recevez les meilleurs devis pour répondre aux besoins de vos copropriétaires</p>
                                                        <p className='txt18 mrgB20'>Publiez vos offres de travaux et recevez plusieurs devis en quelques clics.</p>
                                                        
                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="devisGa4" className='noMrgTB'>Commencer</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vertical mrgB10 bLiteGrey">
                                                    <div className='width90pCenter'>
                                                        <p className='mrgB10 txt30 bold'>La satisfaction de vos copropriétaires est notre priorité </p>
                                                        <p className='txt18 mrgB20'>Chaque artisan et prestataire membre est sélectionné et qualifié pour son propre savoir-faire, expertise et professionnalisme vérifiés.</p>
                                                        
                                                        <div className='mrgB20 noMrgT'>
                                                            <Link to="/signup" className="aReset bT">
                                                                <button id="homePageSignUpButton50w">
                                                                    <p id="satisfactionCoproprietairesGa4" className='noMrgTB'>Voir le réseau</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.Panel>
                                        </Tabs>
                                        </div>
                                        <div className='width50pCenter'>
                                            <div className='vertical jCenter mrgB20 mrgT20 mrgL20'>
                                                <p className='noMrgT mrgB5 capitals txt18 unisT'>Partenaires</p>
                                                <div className='horizontal iCenter'>
                                                    <img    src="https://res.cloudinary.com/do9t6jjdy/image/upload/v1693726907/logo_unis_bt6ef6.jpg" 
                                                            alt='unis' 
                                                            className='imgUnisHome'>
                                                    </img>
                                                    <img    src="https://res.cloudinary.com/do9t6jjdy/image/upload/v1716493434/logo-france_innovation-2_erqtyz.png" 
                                                            alt='franceInnovation' 
                                                            className='imgFranceInnovationHome mrgL40'>
                                                    </img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vertical mrgB10 bLiteGrey width50pCenter">
                                            <div className='width90pCenter'>
                                                <p className='mrgB10 bold txt30'>Vous êtes adhérent UNIS</p>
                                                <div className='vertical'>
                                                        <p className='txt18 mrgR20 lineH20 useRoboto noMrgTB'>
                                                            Vous bénéficiez d’une offre spéciale.
                                                        </p>                                                
                                                        <p className='txt18 noMrgT'>
                                                            Pour la découvrir, inscrivez-vous en cochant la case "Je suis adhérent UNIS".
                                                        </p>
                                                </div>
                                                <div className='mrgB20'>
                                                    <Link to="/signup" className="aReset bT">
                                                        <button id="homePageSignUpButton50w">
                                                            <p id="unisMemberGa4" className='noMrgTB'>S'inscrire</p>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mrgB20"></div>
                                    <div className='width50pCenter txt15 mrgT20'>*Sous réserve d'éligibilité</div>


                                </>
                            )}

                        </div>
                    <Footer/>

                </>
            )}
        </>
    );
}



export default Home;

